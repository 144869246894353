import React, { useContext, useState } from 'react';
import {
  Alert, Button, CircularProgress, Container,
} from '@mui/material';
import { TbArrowRight, TbDownload, TbTrash } from 'react-icons/tb';
import Swal from 'sweetalert2';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';
import TicketsAPI from '../../APIs/TicketsAPI';

function GDPRTools() {
  const { coverSession } = useContext(MemberContext);
  const [coverId, setCoverId] = useState('');
  const [email, setEmail] = useState('');
  const [deleteDate, setDeleteDate] = useState('');
  const api = TicketsAPI();

  const handleRetrieveRecords = () => {
    if (!coverId && !email) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill in either the Cover ID or the email address.',
        icon: 'error',
      });
    }
    api.gdprRetrieveRecords(coverId, email).then((response) => {
      const json = JSON.stringify(response.data, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'cover_tickets_data.json';
      a.click();
    }).catch((e) => {
      Swal.fire({
        title: 'Error',
        text: e.response.data.detail,
        icon: 'error',
      });
    });
  };

  const handleDeleteRecords = () => {
    if (!deleteDate) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill in the date before which you want to delete records.',
        icon: 'error',
      });
      return;
    }
    api.gdprDeleteRecords(deleteDate).then((response) => {
      Swal.fire({
        title: 'Success',
        text: response.data.detail,
        icon: 'success',
      });
    }).catch((e) => {
      Swal.fire({
        title: 'Error',
        text: e.response.data.detail,
        icon: 'error',
      });
    });
  };

  return (
    <div className="gdpr-tools">
      {coverSession ? (
        <Container maxWidth="sm" sx={{ pt: 6, pb: 9 }}>
          <div className="logo">
            <img src="/cover_logo_white.svg" alt="Cover Logo" />
          </div>
          <div className="panel">
            {
              coverSession.member ? (
                <>
                  {
                    ('webcie' in coverSession.member.committees || 'board' in coverSession.member.committees) ? (
                      <>
                        <h2>GDPR requests</h2>
                        <p>
                          Use the form below to download a JSON with all data for a specific person.
                          Personal data cannot be deleted from Tickets, since there&apos;s a
                          retention  obligation for financial records of at least 7 years.
                        </p>
                        <label>
                          Cover ID
                        </label>
                        <input
                          type="number"
                          placeholder="E.g. 2772"
                          value={coverId}
                          onChange={(e) => setCoverId(e.target.value)}
                        />
                        <label>
                          Email address
                        </label>
                        <input
                          type="email"
                          placeholder="E.g. j.doe@student.rug.nl"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                          className="btn btn-blue"
                          sx={{ mt: 3, mb: 3 }}
                          startIcon={<TbDownload />}
                          onClick={handleRetrieveRecords}
                        >
                          Download Data
                        </Button>
                        <h2>Delete archives</h2>
                        <p>
                          After the mandatory retention period expires, all records may
                          be deleted. The form below can be used to delete all records of tickets
                          or purchases made before a certain date.
                        </p>
                        <label>Delete all records before</label>
                        <input
                          type="date"
                          value={deleteDate}
                          onChange={(e) => setDeleteDate(e.target.value)}
                        />
                        <Button
                          className="btn btn-red"
                          sx={{ mt: 3, mb: 3 }}
                          startIcon={<TbTrash />}
                          onClick={handleDeleteRecords}
                        >
                          Delete Records
                        </Button>
                      </>
                    ) : (
                      <>
                        <Alert
                          severity="error"
                        >
                          This tool is only available for AC/DCee Administrators and Board.
                        </Alert>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <Alert
                    severity="error"
                  >
                    This is only available for logged in Cover members.
                  </Alert>
                  <Button
                    className="btn btn-red"
                    endIcon={<TbArrowRight />}
                    sx={{ mt: 3 }}
                    href={`${coverSession.cover_website}/login?referrer=${window.location.href}`}
                  >
                    Log In
                  </Button>
                </>
              )
            }
          </div>
        </Container>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default GDPRTools;
