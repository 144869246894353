import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, CircularProgress, Container,
} from '@mui/material';
import moment from 'moment';
import TicketsAPI from '../../APIs/TicketsAPI';
import NotFound from '../NotFound';

function Purchase() {
  const [purchase, setPurchase] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const api = TicketsAPI();
  const { id } = useParams();
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    // Fetch purchase by id
    api.getPurchase(id).then((response) => {
      setPurchase(response.data);
    }).catch(() => {
      setNotFound(true);
    });
  }, []);

  useEffect(() => {
    if (purchase) {
      const interval = setInterval(() => {
        const now = moment().utcOffset('+0100');
        const target = moment(purchase.details.expiresAt).utc();
        const diff = target.diff(now);

        if (diff < 0) {
          clearInterval(interval);
          setCountdown('00:00');
        } else {
          const duration = moment.duration(diff);
          const minutes = Math.floor(duration.asMinutes()).toString().padStart(2, '0');
          const seconds = duration.seconds().toString().padStart(2, '0');
          setCountdown(`${minutes}:${seconds}`);
        }
      }, 1000);
    }
  }, [purchase]);

  if (notFound) {
    return <NotFound />;
  }

  return purchase && countdown ? (
    <div className="purchase">
      <Container maxWidth="sm">
        <div className="logo">
          <img src="/cover_logo_white.svg" alt="Cover Logo" />
        </div>
        <div className="panel">
          {purchase.status === 'PENDING_PAYMENT' && (
            <>
              <h2>Tickets reserved</h2>
              <p>
                Your tickets have been reserved.
                You have
                {' '}
                <b>
                  {countdown}
                  {' '}
                  minutes
                </b>
                {' '}
                to complete the purchase before the tickets get released.
              </p>
              <br />
              <h2>
                Total: €
                {purchase.details.amount.value}
              </h2>
              <Button
                className="btn btn-red"
                disabled={countdown === '00:00'}
                // eslint-disable-next-line
                href={purchase.details._links.checkout.href}
                sx={{ mt: 3 }}
              >
                Complete purchase
              </Button>
            </>
          )}
          {purchase.status === 'CONFIRMED' && (
            <>
              <h2>Purchase confirmed</h2>
              <p>
                Your purchase has been confirmed and the
                tickets have been sent to your email address.
              </p>
            </>
          )}
          {purchase.status !== 'PENDING_PAYMENT' && purchase.status !== 'CONFIRMED' && (
            <>
              <h2>Purchase expired</h2>
              <p>
                This purchase is not available anymore.
              </p>
            </>
          )}
        </div>
      </Container>
    </div>
  ) : (
    <div className="purchase">
      <CircularProgress />
    </div>
  );
}

export default Purchase;
