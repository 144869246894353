import React, { useContext } from 'react';
import {
  Alert, Button, CircularProgress, Container,
} from '@mui/material';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { TbArrowRight } from 'react-icons/tb';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';

function Scanner() {
  const { coverSession } = useContext(MemberContext);
  const navigate = useNavigate();

  const onDecode = (result) => {
    if (validator.isUUID(result)) {
      navigate(`/ticket/${result}`);
    }
  };

  return (
    <div className="scanner-view">
      {coverSession ? (
        <>
          {coverSession.member ? (
            <div className="scanner">
              <QrScanner onError={console.error} onDecode={onDecode} />
            </div>
          ) : (
            <Container maxWidth="sm" sx={{ minHeight: 'calc(100vh - 61px)' }}>
              <div className="logo">
                <img src="/cover_logo_white.svg" alt="Cover Logo" />
              </div>
              <div className="panel">
                <Alert
                  severity="error"
                >
                  This is only available for logged in Cover members.
                </Alert>
                <Button
                  className="btn btn-red"
                  endIcon={<TbArrowRight />}
                  sx={{ mt: 3 }}
                  href={`${coverSession.cover_website}/login?referrer=${window.location.href}`}
                >
                  Log In
                </Button>
              </div>
            </Container>
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default Scanner;
