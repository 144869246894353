import apiUtils from '../Utils/ApiUtils';
import getApiHost from '../Utils/UrlUtils';

export default function TicketsAPI() {
  const utils = apiUtils();

  return {
    getEvent(slug) {
      return utils
        .getNoAuth(`${getApiHost()}/events/${slug}/`);
    },
    getCoverSession() {
      return utils
        .getNoAuth(`${getApiHost()}/cover-session/`, { withCredentials: true });
    },
    purchaseTickets(
      eventSlug,
      coverId,
      buyerName,
      buyerEmail,
      paymentMethod,
      ticketData,
      dietaryRequirements,
    ) {
      return utils
        .postNoAuth(`${getApiHost()}/events/${eventSlug}/purchase/`, {
          cover_id: coverId,
          buyer_name: buyerName,
          buyer_email: buyerEmail,
          payment_method: paymentMethod,
          tickets: ticketData,
          dietary_requirements: dietaryRequirements,
        }, {
          withCredentials: true,
        });
    },
    getPurchase(id) {
      return utils
        .getNoAuth(`${getApiHost()}/purchases/${id}/`);
    },
    getTicket(id) {
      return utils
        .getNoAuth(`${getApiHost()}/tickets/${id}/`, { withCredentials: true });
    },
    useTicket(id) {
      return utils
        .postNoAuth(`${getApiHost()}/tickets/${id}/use/`, {}, { withCredentials: true });
    },
    gdprDeleteRecords(beforeDate) {
      return utils
        .postNoAuth(`${getApiHost()}/gdpr/delete-records/`, { before_date: beforeDate }, { withCredentials: true });
    },
    gdprRetrieveRecords(coverId, email) {
      return utils
        .getNoAuth(
          `${getApiHost()}/gdpr/retrieve-records/?cover_id=${coverId}&email=${email}`,
          { withCredentials: true },
        );
    },
    getEventStats(eventSlug) {
      return utils
        .getNoAuth(`${getApiHost()}/events/${eventSlug}/stats/`, { withCredentials: true });
    },
    getTickets(id) {
      return utils
        .getNoAuth(`${getApiHost()}/ticket-types/${id}/tickets/`, { withCredentials: true });
    },
    getSomething(id) {
      return utils
        .get(`${getApiHost()}/tasks/${id}/`);
    },
    postSomething(data) {
      return utils
        .post(`${getApiHost()}/tasks/`, data);
    },
  };
}
