import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { mapToAuthRequest } from '../../Utils/RequestUtils';
import { REFRESH_URL, REQUEST_URL } from '../../Constants/URLS';

export const UserContext = createContext();
const FRONTEND_ACCESS_TOKEN = 'tickets_frontend_frontend_access_token';
const FRONTEND_REFRESH_TOKEN = 'tickets_frontend_frontend_refresh_token';

export default function UserProvider({ children }) {
  function getAccessTokenLocal() {
    return {
      accessToken: localStorage.getItem(FRONTEND_ACCESS_TOKEN) || '',
      refreshToken: localStorage.getItem(FRONTEND_REFRESH_TOKEN) || '',
    };
  }

  const [tokens, setTokens] = useState(getAccessTokenLocal());
  const [user, setUser] = useState({});

  function setLocalAccessToken({ accessToken, refreshToken }) {
    localStorage[FRONTEND_ACCESS_TOKEN] = accessToken;
    localStorage[FRONTEND_REFRESH_TOKEN] = refreshToken;
  }

  async function getConfig() {
    const credential = getAccessTokenLocal();

    return {
      headers: {
        Authorization: `Bearer ${credential?.accessToken ?? ''}`,
      },
    };
  }

  function storeTokens(accessToken, refreshToken) {
    setTokens({
      accessToken,
      refreshToken,
    });
  }

  function logout() {
    // TODO: invalidate token in api
    setTokens({
      accessToken: '',
      refreshToken: '',
    });
  }

  function refresh() {
    const credential = getAccessTokenLocal();
    return axios
      .post(`${REFRESH_URL}`, { refresh: credential.refreshToken })
      .then((response) => {
        storeTokens(response.data.access, response.data.refresh);
        return {
          headers: {
            Authorization: `Bearer ${response.data.access}`,
          },
        };
      }).catch((error) => {
        logout();
        throw error;
      });
  }

  function getUserInfo() {
    function mapToAuthRequestLoc(request) {
      return mapToAuthRequest(request, getConfig, refresh, logout);
    }

    const request = (config) => axios.get(`${REQUEST_URL}`, config);

    return mapToAuthRequestLoc(request)
      .then((result) => setUser(result.data));
  }

  useEffect(() => {
    setLocalAccessToken(tokens);
    if (tokens.accessToken.length > 0 && tokens.refreshToken.length > 0) {
      getUserInfo();
    }
  }, [tokens]);

  const [userData] = useState({
    storeTokens,
    logout,
    getConfig,
  });

  return (
    <UserContext.Provider
      value={{
        ...userData, user, tokens, refresh,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
