import React from 'react';
import { Container } from '@mui/material';

function NotFound() {
  return (
    <div className="not-found">
      <Container maxWidth="sm">
        <img src="/cover_logo_white.svg" alt="Cover Logo" className="logo" />
        <div className="panel">
          <img src="/404.png" alt="404" />
        </div>
      </Container>
    </div>
  );
}

export default NotFound;
