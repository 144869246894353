import React, { useContext, useEffect, useState } from 'react';
import {
  Button, CircularProgress, Container, LinearProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';
import TicketsAPI from '../../APIs/TicketsAPI';

function Stats() {
  const { slug } = useParams();
  const { coverSession } = useContext(MemberContext);
  const api = TicketsAPI();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (coverSession) {
      if (!coverSession.member) navigate(`/event/${slug}`);
      api.getEventStats(slug).then((response) => {
        setEvent(response.data);
      }).catch(() => {
        navigate(`/event/${slug}`);
      });
    }
  }, [coverSession]);

  return (
    <div className="stats">
      {coverSession && event ? (
        <Container maxWidth="sm">
          <div className="logo">
            <img src="/cover_logo_white.svg" alt="Cover Logo" />
          </div>
          <Button
            className="btn btn-blue btn-full-width"
            startIcon={<TbArrowLeft />}
            href={`/event/${slug}`}
            sx={{ mb: 3 }}
          >
            Back
          </Button>
          <div className="panel">
            <h1>{event.title}</h1>
            <LinearProgress
              variant="determinate"
              value={(event.sold / event.capacity) * 100}
              sx={{ mt: 2, mb: 1 }}
            />
            <div className="event-stats">
              <div>
                <b>Sold:</b>
                {' '}
                {event.sold}
              </div>
              <div>
                <b>Total capacity:</b>
                {' '}
                {event.capacity}
              </div>
            </div>
          </div>
          {event.ticket_types.map((ticket) => (
            <div className="panel ticket-type">
              <div>
                <h2>
                  {ticket.name}
                  {' '}
                  (€
                  {' '}
                  {ticket.price}
                  )
                </h2>
                <div className="ticket-stats">
                  {ticket.capacity && (
                    <LinearProgress
                      variant="determinate"
                      value={(ticket.sold / ticket.capacity) * 100}
                    />
                  )}
                  <span>
                    Sold:
                    {' '}
                    <b>{ticket.sold}</b>
                    {ticket.capacity && ` / ${ticket.capacity}`}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  className="btn btn-red btn-icon"
                  href={`/tickets/${ticket.id}`}
                >
                  <TbArrowRight />
                </Button>
              </div>
            </div>
          ))}
        </Container>
      ) : (
        <CircularProgress className="loading" />
      )}
    </div>
  );
}

export default Stats;
