import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.scss';
import { NotificationContainer } from 'react-notifications';
import history from './Utils/history';
import Pages from './Constants/pages';
import UserProvider from './Providers/UserProvider/UserProvider';
import NotificationProvider from './Providers/NotificationProvider/NotificationProvider';
import CustomRouter from './Utils/CustomRouters';
import AuthenticatedRoute from './Components/AuthenticatedRoute/AuthenticatedRoute';
import MemberProvider from './Providers/MemberProvider/MemberProvider';
import GDPRButton from './Components/GDPRButton';

function App() {
  const pages = Pages();

  return (
    <UserProvider>
      <CustomRouter history={history}>
        <div className="App">
          <MemberProvider>
            <NotificationProvider>
              <>
                <NotificationContainer />
                <GDPRButton />
                <Routes>
                  {
                  Object.keys(pages).map((key, idx) => (
                    <Route
                      key={idx}
                      path={pages[key].path}
                      element={pages[key].loginNeeded
                        ? <AuthenticatedRoute element={pages[key].element} />
                        : pages[key].element}
                    />
                  ))
                }
                </Routes>
              </>
            </NotificationProvider>
          </MemberProvider>
        </div>
      </CustomRouter>
    </UserProvider>
  );
}

export default App;
