import React, {
  createContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import TicketsAPI from '../../APIs/TicketsAPI';

export const MemberContext = createContext(null);

export function MemberProvider({ children }) {
  const api = TicketsAPI();
  const [coverSession, setCoverSession] = useState(null);

  useMemo(() => {
    api.getCoverSession().then((response) => {
      setCoverSession(response.data);
    });
  }, []);

  const value = {
    coverSession,
  };

  return (
    <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
  );
}

MemberProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
};

export default MemberProvider;
