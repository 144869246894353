import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert, Button,
  CircularProgress, Container, Grid, Tooltip,
} from '@mui/material';
import {
  TbArrowLeft,
  TbArrowRight,
  TbCalendarTime, TbChartBar, TbCircleMinus, TbCirclePlus, TbMapPin, TbShoppingCart,
} from 'react-icons/tb';
import Moment from 'moment';
import Swal from 'sweetalert2';
import TicketsAPI from '../../APIs/TicketsAPI';
import NotFound from '../NotFound';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';

function Event() {
  const { slug } = useParams();
  const [event, setEvent] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [tickets, setTickets] = useState([]);
  const api = TicketsAPI();
  // const [coverSession, setCoverSession] = useState(null);
  const [checkout, setCheckout] = useState(false);
  const [buyerName, setBuyerName] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [coverId, setCoverId] = useState(null);
  const navigate = useNavigate();
  const { coverSession } = useContext(MemberContext);
  const [dietaryRequirements, setDietaryRequirements] = useState('');

  useEffect(() => {
    // Fetch event by slug
    api.getEvent(slug).then((response) => {
      setEvent(response.data);
      const newTickets = {};
      response.data.ticket_types.forEach((ticket) => {
        newTickets[ticket.id] = 0;
      });
      setTickets(newTickets);
    }).catch(() => {
      setNotFound(true);
    });
  }, []);

  useEffect(() => {
    if (coverSession && coverSession.member) {
      let name = coverSession.member.voornaam;
      if (coverSession.member.tussenvoegsel) {
        name += ` ${coverSession.member.tussenvoegsel}`;
      }
      name += ` ${coverSession.member.achternaam}`;
      setBuyerName(name);
      setBuyerEmail(coverSession.member.email);
      setCoverId(coverSession.member.id);
    }
  }, [coverSession]);

  if (notFound) {
    return <NotFound />;
  }

  const incrementTicket = (id) => {
    const newTickets = { ...tickets };
    newTickets[id] += 1;
    setTickets(newTickets);
  };

  const decrementTicket = (id) => {
    const newTickets = { ...tickets };
    if (newTickets[id] > 0) {
      newTickets[id] -= 1;
      setTickets(newTickets);
    }
  };

  const purchase = () => {
    if (!buyerName || !buyerEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please fill in your full name and email address.',
      });
      return;
    }
    if (!paymentMethod) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please select a payment method.',
      });
      return;
    }
    // Check if there is at least one ticket
    let ticketCount = 0;
    Object.keys(tickets).forEach((key) => {
      ticketCount += tickets[key];
    });
    if (ticketCount === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Please select at least one ticket.',
      });
      return;
    }
    const ticketData = [];
    Object.keys(tickets).forEach((key) => {
      if (tickets[key] > 0) {
        ticketData.push({
          id: key,
          quantity: tickets[key],
        });
      }
    });
    api.purchaseTickets(
      event.slug,
      coverId,
      buyerName,
      buyerEmail,
      paymentMethod,
      ticketData,
      dietaryRequirements,
    )
      .then((response) => {
        navigate(`/purchase/${response.data.purchase.id}`);
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.detail,
        });
      });
  };

  const eventSaleClosed = event && ((event.sell_start && Moment(event.sell_start)) > Moment()
    || (event.sell_end && Moment(event.sell_end) < Moment()));

  return event && coverSession && (
    <div className="event">
      {event ? (
        <Container maxWidth="sm">
          <div className="logo">
            <img src="/cover_logo_white.svg" alt="Cover Logo" />
          </div>
          {coverSession.member && ('webcie' in coverSession.member.committees
            || 'board' in coverSession.member.committees
            || event.committee in coverSession.member.committees) && (
            <Button
              className="btn btn-blue btn-full-width"
              startIcon={<TbChartBar />}
              sx={{ mb: 3 }}
              href={`/event/${event.slug}/stats`}
            >
              Ticket Sales
            </Button>
          )}
          <div className="panel">
            <h1>{event.title}</h1>
            <span className="event-property">
              <TbCalendarTime />
              {' '}
              {Moment(event.start_time).format('YYYY-MM-DD HH:mm')}
              {' - '}
              {Moment(event.end_time).format('YYYY-MM-DD HH:mm')}
            </span>
            <span className="event-property">
              <TbMapPin />
              {' '}
              {event.location}
            </span>
            {event.details && <p className="event-details">{event.details}</p>}
          </div>
          {/* {JSON.stringify(coverSession)} */}
          {event.ticket_types.filter((ticket) => ticket.cover_only).length > 0
            && !coverSession.member && (
              <Alert
                severity="info"
                sx={{ mt: 3 }}
                action={(
                  <Button
                    color="inherit"
                    size="small"
                    endIcon={<TbArrowRight />}
                    href={`${coverSession.cover_website}/login?referrer=${window.location.href}`}
                  >
                    Log In
                  </Button>
                )}
              >
                Some tickets are only available to Cover members.
              </Alert>
          )}
          {checkout ? (
            <>
              <div className="panel checkout-panel">
                <h2>Checkout</h2>
                <label>Full Name</label>
                <input
                  type="text"
                  value={buyerName}
                  onChange={(e) => setBuyerName(e.target.value)}
                  disabled={!!coverSession.member}
                  placeholder="Ex: Jeroen van den Berg"
                />
                <label>Email</label>
                <input
                  type="email"
                  value={buyerEmail}
                  onChange={(e) => setBuyerEmail(e.target.value)}
                  disabled={!!coverSession.member}
                  placeholder="Ex: jeroen@svcover.nl"
                />
                <label>Payment Method</label>
                <select onChange={(e) => setPaymentMethod(e.target.value)}>
                  <option value="" selected={paymentMethod === ''} disabled>Select a payment method</option>
                  {event.payment_methods.map((method) => (
                    <option
                      key={method.id}
                      value={method.id}
                      selected={paymentMethod === method.id}
                    >
                      {method.name}
                      {' '}
                      (+ €
                      {' '}
                      {method.commission}
                      )
                    </option>
                  ))}
                </select>
                {event.enable_dietary_requirements && (
                  <>
                    <label>
                      Do any of the people attending have dietary requirements?
                      <span className="optional">optional</span>
                    </label>
                    <textarea
                      placeholder="Ex: Jeroen is allergic to peanuts."
                      value={dietaryRequirements}
                      onChange={(e) => setDietaryRequirements(e.target.value)}
                    />
                  </>
                )}
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <Button
                    className="btn btn-blue btn-full-width"
                    startIcon={<TbArrowLeft />}
                    sx={{ mt: 3 }}
                    onClick={() => setCheckout(false)}
                  >
                    Go Back
                  </Button>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Button
                    className="btn btn-red btn-full-width"
                    startIcon={<TbShoppingCart />}
                    sx={{ mt: 3 }}
                    onClick={() => purchase()}
                  >
                    Purchase
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <div className="panel tickets-panel">
                <div className="tickets-list">
                  {event.ticket_types.map((ticket) => (
                    <Tooltip
                      title={`${ticket.restrict_to_committee && !(coverSession.member && ticket.restrict_to_committee in coverSession.member.committees) ? 'Restricted to committee members. ' : ''}${ticket.cover_only && !coverSession.member ? 'Only available for Cover members. ' : ''}${ticket.is_sold_out ? 'Sold out. ' : ''}${!ticket.is_sale_open || eventSaleClosed ? 'Sale not open. ' : ''}`}
                      placement="top"
                      enterTouchDelay={0}
                      arrow
                    >
                      <div
                        className={`ticket-list-item
                        ${(ticket.cover_only && !coverSession.member) || ticket.is_sold_out
                        || !ticket.is_sale_open || eventSaleClosed
                        || (ticket.restrict_to_committee && !(coverSession.member && ticket.restrict_to_committee in coverSession.member.committees)) ? 'disabled' : ''}
                        `}
                        key={ticket.id}
                      >
                        <div className="ticket-info">
                          {ticket.name}
                          <span>
                            €
                            {ticket.price}
                          </span>
                        </div>
                        <div className="ticket-selector">
                          <Button
                            onClick={() => decrementTicket(ticket.id)}
                            disabled={tickets[ticket.id] === 0
                              || (ticket.cover_only && !coverSession.member)
                              || ticket.is_sold_out
                              || !ticket.is_sale_open
                              // eslint-disable-next-line
                              || (ticket.restrict_to_committee && !(coverSession.member && ticket.restrict_to_committee in coverSession.member.committees))
                              || eventSaleClosed}
                          >
                            <TbCircleMinus />
                          </Button>
                          <div className="ticket-count">
                            {tickets[ticket.id]}
                          </div>
                          <Button
                            onClick={() => incrementTicket(ticket.id)}
                            disabled={(ticket.cover_only && !coverSession.member)
                              || ticket.is_sold_out
                              || !ticket.is_sale_open
                              // eslint-disable-next-line
                              || (ticket.restrict_to_committee && !(coverSession.member && ticket.restrict_to_committee in coverSession.member.committees))
                              || eventSaleClosed}
                          >
                            <TbCirclePlus />
                          </Button>
                        </div>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
              <Button
                className="btn btn-red btn-full-width"
                startIcon={<TbShoppingCart />}
                sx={{ mt: 3 }}
                onClick={() => setCheckout(true)}
              >
                Checkout
              </Button>
            </>
          )}
        </Container>
      ) : (
        <CircularProgress className="loading" />
      )}
    </div>
  );
}

export default Event;
