import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function AuthenticatedRoute({ element }) {
  const { tokens } = useContext(UserContext);

  return tokens?.accessToken?.length > 0 ? element : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  );
}

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default AuthenticatedRoute;
