import React, { useContext } from 'react';
import { Button, Tooltip } from '@mui/material';
import { TbShieldCog } from 'react-icons/tb';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';

function GDPRButton() {
  const { coverSession } = useContext(MemberContext);

  if (!coverSession) return null;

  if (!coverSession.member) return null;

  if (!('webcie' in coverSession.member.committees || 'board' in coverSession.member.committees)) {
    return null;
  }

  return (
    <Tooltip title="GDPR Tools" placement="top" arrow>
      <Button
        className="gdpr-button"
        href="/gdpr"
      >
        <TbShieldCog />
      </Button>
    </Tooltip>
  );
}

export default GDPRButton;
