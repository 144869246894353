import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, Chip, CircularProgress, Container,
} from '@mui/material';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'moment';
import TicketsAPI from '../../APIs/TicketsAPI';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';
import NotFound from '../NotFound';

function TicketSales() {
  const { id } = useParams();
  const api = TicketsAPI();
  const { coverSession } = useContext(MemberContext);
  const [ticket, setTicket] = useState(null);

  const [notFound, setNotFound] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (coverSession) {
      api.getTickets(id).then((res) => {
        setTicket(res.data);
      }).catch(() => {
        setNotFound(true);
      });
    }
  }, [coverSession]);

  useEffect(() => {
    if (ticket) {
      if (search !== '') {
        const newTickets = ticket.tickets.filter(
          (t) => t.buyer_name.toLowerCase().includes(search.toLowerCase())
          || t.buyer_email.toLowerCase().includes(search.toLowerCase()),
        );
        setFilteredTickets(newTickets);
      } else {
        setFilteredTickets(ticket.tickets);
      }
    }
  }, [ticket, search]);

  const dietaryRequirements = filteredTickets.some((t) => t.dietary_requirements !== '');

  const columns = [
    {
      field: 'buyer_name',
      headerName: 'Buyer Name',
      flex: 1,
      renderCell: (params) => <b>{params.row.buyer_name}</b>,
    },
    { field: 'buyer_email', headerName: 'Buyer Email', flex: 1 },
    {
      field: 'cover_id',
      headerName: 'Cover Profile',
      flex: 1,
      renderCell: (params) => params.row.cover_id && (
        <Button
          endIcon={<TbArrowRight />}
          href={`${coverSession.cover_website}/profile?lid=${params.row.cover_id}`}
          className="cover-profile-btn"
          target="_blank"
        >
          Cover Profile
        </Button>
      ),
    },
    {
      field: 'used',
      headerName: 'Used',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.used ? `Used at ${Moment(params.row.used).format('YYYY-MM-DD HH:mm')}` : 'Not used'}
          sx={{ ml: 1 }}
          color={params.row.used ? 'error' : 'info'}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Purchased at',
      flex: 1,
      renderCell: (params) => Moment(params.row.created_at).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  // Only add the dietary requirements column if needed
  if (dietaryRequirements) {
    columns.push({
      field: 'dietary_requirements',
      headerName: 'Dietary Requirements',
      flex: 1,
    });
  }

  if (notFound) return <NotFound />;

  return (
    <div className="ticket-sales">
      {coverSession && ticket ? (
        <Container maxWidth="lg">
          <div className="logo">
            <img src="/cover_logo_white.svg" alt="Cover Logo" />
          </div>
          <Button
            className="btn btn-blue btn-full-width"
            startIcon={<TbArrowLeft />}
            href={`/event/${ticket.event_slug}/stats`}
            sx={{ mb: 3 }}
          >
            Back
          </Button>
          <div className="panel">
            <h1>
              <b>
                {ticket.name}
                {' '}
                (€
                {' '}
                {ticket.price}
                )
              </b>
              {' - '}
              {ticket.event_title}
            </h1>
            <input
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <DataGrid
              columns={columns}
              rows={filteredTickets}
            />
          </div>
        </Container>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default TicketSales;
