import React from 'react';
import Event from '../Views/Event';
import NotFound from '../Views/NotFound';
import Purchase from '../Views/Purchase';
import Ticket from '../Views/Ticket';
import Scanner from '../Views/Scanner';
import GDPRTools from '../Views/GDPRTools';
import Stats from '../Views/Stats';
import TicketSales from '../Views/TicketSales';

export default function Pages() {
  return {
    stats: {
      id: 1, path: '/event/:slug/stats', showMenu: false, element: <Stats />,
    },
    event: {
      id: 2, path: '/event/:slug', showMenu: false, element: <Event />,
    },
    purchase: {
      id: 3, showMenu: false, path: '/purchase/:id', element: <Purchase />,
    },
    ticket: {
      id: 4, showMenu: false, path: '/ticket/:id', element: <Ticket />,
    },
    scanner: {
      id: 5, showMenu: false, path: '/scanner', element: <Scanner />,
    },
    gpdr_tools: {
      id: 6, showMenu: false, path: '/gdpr', element: <GDPRTools />,
    },
    tickets: {
      id: 7, showMenu: false, path: '/tickets/:id', element: <TicketSales />,
    },
    not_found: {
      id: 10, showMenu: false, path: '*', element: <NotFound />,
    },
  };
}
