import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Button, Chip, Container, Tooltip,
} from '@mui/material';
import {
  TbCalendarTime, TbIdBadge, TbMail, TbMapPin, TbToolsKitchen2, TbUser, TbUsersGroup,
} from 'react-icons/tb';
import Moment from 'moment/moment';
import TicketsAPI from '../../APIs/TicketsAPI';
import { MemberContext } from '../../Providers/MemberProvider/MemberProvider';

function Ticket() {
  const [ticket, setTicket] = useState(null);
  const api = TicketsAPI();
  const { id } = useParams();
  const navigate = useNavigate();
  const { coverSession } = useContext(MemberContext);

  useEffect(() => {
    api.getTicket(id).then((response) => {
      setTicket(response.data);
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Ticket not found or you are not authorized to view this ticket.',
      }).then(() => {
        navigate('/scanner');
      });
    });
  }, []);

  const useTicket = () => {
    api.useTicket(id).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Ticket has been marked as used.',
      }).then(() => {
        navigate('/scanner');
      });
    });
  };

  return (
    <div className="ticket">
      {ticket && (
        <Container maxWidth="sm">
          <div className="logo">
            <img src="/cover_logo_white.svg" alt="Cover Logo" />
          </div>
          <div className="panel">
            <h1>{ticket.ticket_type.event.title}</h1>
            <span className="event-property">
              <TbCalendarTime />
              {' '}
              {Moment(ticket.ticket_type.event.start_time).format('YYYY-MM-DD HH:mm')}
              {' - '}
              {Moment(ticket.ticket_type.event.end_time).format('YYYY-MM-DD HH:mm')}
            </span>
            <span className="event-property">
              <TbMapPin />
              {' '}
              {ticket.ticket_type.event.location}
            </span>
            <span className="event-property">
              <TbUsersGroup />
              {' '}
              {coverSession && coverSession.member.committees[ticket.ticket_type.event.committee]}
            </span>
          </div>
          <div className="panel" style={{ marginTop: 30 }}>
            <h2>
              {ticket.ticket_type.name}
              {' '}
              (€
              {' '}
              {ticket.ticket_type.price}
              )
              {' '}
              <Chip
                label={ticket.used ? `Used at ${Moment(ticket.used).format('YYYY-MM-DD HH:mm')}` : 'Not used'}
                sx={{ ml: 1 }}
                color={ticket.used ? 'error' : 'info'}
              />
            </h2>
            <span className="event-property">
              <TbCalendarTime />
              {' '}
              <b>Purchased at:</b>
              {' '}
              {Moment(ticket.purchase.created_at).format('YYYY-MM-DD HH:mm')}
            </span>
            <span className="event-property">
              <TbUser />
              {' '}
              <b>Buyer name:</b>
              {' '}
              {ticket.purchase.buyer_name}
            </span>
            <span className="event-property">
              <TbMail />
              {' '}
              <b>Buyer email:</b>
              {' '}
              {ticket.purchase.buyer_email}
            </span>
            <span className="event-property">
              <TbIdBadge />
              {' '}
              <b>Cover ID:</b>
              {' '}
              {ticket.purchase.cover_id || 'None'}
            </span>
            <span className="event-property">
              <TbToolsKitchen2 />
              {' '}
              <b>Dietary Requirements:</b>
              {' '}
              {ticket.purchase.dietary_requirements || 'None'}
            </span>
          </div>
          <div style={{ marginTop: 30 }}>
            <Tooltip title={ticket.used && 'This ticket has already been used.'} placement="top" arrow>
              <Button
                className="btn btn-red btn-full-width"
                disabled={ticket.used}
                onClick={useTicket}
              >
                Mark as used
              </Button>
            </Tooltip>
            <Button
              sx={{ mt: 3 }}
              className="btn btn-secondary btn-full-width"
              onClick={() => navigate('/scanner')}
            >
              Return to scanner
            </Button>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Ticket;
